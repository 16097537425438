<template>
    <div>
        <div id="clinic-map" style="width: 100%; height: 300px;">
        </div>
        <div v-if="!loading" class="w-100 text-center p-1">
            <span class="" style="text-decoration: underline; cursor: pointer;" @click="getUserLocation()">Point My Current Location</span>
        </div>
    </div>
</template>

<script>
import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';
import clinicGeoJSON from './../../data/map/clinic_geojson.json'

export default {
    props: ["clinicType"],
    data(){
        return {
            map: null,
            markers: [],
            loading: true,
            allClinics: null,
            filteredClinics: null
        }
    },
    watch: {
        clinicType(_newVal){
            this.filteredClinics = Object.assign({}, this.allClinicGeoJSON)

            if(this.clinicType){
                if(this.clinicType == "Dental Clinic"){
                    this.filteredClinics.features = this.allClinicGeoJSON.features.filter(feature => feature.properties.title.toLowerCase().includes('selcare dental'))
                }else if(this.clinicType == "GP Clinic"){
                    this.filteredClinics.features = this.allClinicGeoJSON.features.filter(feature => feature.properties.title.toLowerCase().includes('selcare clinic'))
                }

                //another filter - exclude any clinic that has hq term
                this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('hq'))
                this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('sri rampai'))
                this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('worldwide'))
                this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('world wide'))
            }

            //remove all markers
            this.markers.forEach(marker => marker.remove())
            this.markers.length = 0;

            // add markers to map
            for (const feature of this.filteredClinics.features) {
                // create a HTML element for each feature
                const el = document.createElement('div');
                el.className = 'clinic-map-marker';

                // make a marker for each feature and add to the map
                let marker = new mapboxgl
                    .Marker(el)
                    .setLngLat(feature.geometry.coordinates)
                    .setPopup(
                        new mapboxgl.Popup({ offset: 25 }) // add popups
                        .setHTML(
                            `<h6>${feature.properties.title}</h6>
                            <p>${feature.properties.description}</p>
                            `
                        )
                    )
                    .addTo(this.map);

                marker.getElement()
                .addEventListener('click', (e) => {
                    this.$emit('clinic-marker-selected', feature.properties.title);
                })

                this.markers.push(marker)
            }
        }
    },
    methods: {
        getUserLocation(){
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(this.showUserMap);
            } else {
                console.log("Geolocation is not supported by this browser.");
            }
        },
        showUserMap(position) {
            var latitude = position.coords.latitude;
            var longitude = position.coords.longitude;

            const el = document.createElement('div');
            el.className = 'clinic-map-user-marker';

            new mapboxgl.Marker(el)
                .setLngLat([longitude, latitude])
                .addTo(this.map);

            this.map.setCenter([longitude, latitude]);
            this.map.flyTo({zoom: 10})
        }
    },
    mounted(){
        this.loading = true

        this.allClinicGeoJSON = clinicGeoJSON
        this.filteredClinics = Object.assign({}, this.allClinicGeoJSON)


        mapboxgl.accessToken = process.env.VUE_APP_MAPBOX_TOKEN;
        this.map = new mapboxgl.Map({
            container: 'clinic-map',
            style: 'mapbox://styles/mapbox/streets-v11',
            center: [101.518295,3.0721677],
            zoom: 8.3
        });

        //filter out the clinic
        if(this.clinicType){
            if(this.clinicType == "Dental Clinic"){
                this.filteredClinics.features = this.allClinicGeoJSON.features.filter(feature => feature.properties.title.toLowerCase().includes('selcare dental'))
            }else if(this.clinicType == "GP Clinic"){
                this.filteredClinics.features = this.allClinicGeoJSON.features.filter(feature => feature.properties.title.toLowerCase().includes('selcare clinic'))
            }

            //another filter - exclude any clinic that has hq term
            this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('hq'))
            this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('sri rampai'))
            this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('worldwide'))
            this.filteredClinics.features = this.filteredClinics.features.filter(feature => !feature.properties.title.toLowerCase().includes('world wide'))
        }

        // add markers to map
        for (const feature of this.filteredClinics.features) {
            // create a HTML element for each feature
            const el = document.createElement('div');
            el.className = 'clinic-map-marker';

            // make a marker for each feature and add to the map
            let marker = new mapboxgl
                .Marker(el)
                .setLngLat(feature.geometry.coordinates)
                .setPopup(
                    new mapboxgl.Popup({ offset: 25 }) // add popups
                    .setHTML(
                        `<h6>${feature.properties.title}</h6>
                        <p>${feature.properties.description}</p>
                        <span>Operation Hours</span>
                        <ul>
                            <li>Monday - Friday: 8:00am - 10:00pm</li>
                        </ul>
                        <span>Contact Number</span>
                        <ul>
                            <li>${feature.properties.phone}</li>
                        </ul>
                        `
                    )
                )
                .addTo(this.map);

            marker.getElement()
            .addEventListener('click', (e) => {
                this.$emit('clinic-marker-selected', feature.properties.title);
            })

            this.markers.push(marker)
        }

        this.loading = false
    }
}
</script>

<style>
.clinic-map-marker {
  background-image: url('./../../assets/marker-1.png');
  background-size: cover;
  width: 35px;
  height: 55px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.clinic-map-user-marker {
  background-image: url('./../../assets/user-marker.jpeg');
  background-size: cover;
  width: 45px;
  height: 55px;
  /* border-radius: 50%; */
  cursor: pointer;
}

.clinic-map-popup {
  max-width: 200px;
}

.clinic-map-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}

.mapboxgl-popup-content p {
    font-size: 10px;
    line-height: 12px;
}

.mapboxgl-popup-content h6{
    font-size: 0.85rem;
}

.mapboxgl-popup-content span{
    font-size: 11px;
}

.mapboxgl-popup-content ul{
    font-size: 10px;
    line-height: 12px;
    padding: 0px 0px 0px 15px;
}
</style>